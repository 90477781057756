import React from 'react';
import styled from 'styled-components';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Modal from '../../../components/JSModal';

function InfoPopUp({ showInfoModal, toggleInfoModal, dailyWheelAssets }) {
  return (
    <Modal style={modalStyle} isVisible={showInfoModal} hideDefaultBackground hideCloseButton onClick={toggleInfoModal}>
      <ModalContainer>
        <ImageWrapper>
          <CloseButton />
          <InfoModalBackgroundImage src={dailyWheelAssets.DAILY_SPIN_COLUMN_INFO_POPUP} />
        </ImageWrapper>
      </ModalContainer>
    </Modal>
  );
}

export default InfoPopUp;

const modalStyle = {
  padding: '15vh',
};

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  max-width: 900px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const InfoModalBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
`;

const CloseButton = styled.img.attrs({
  src: assetSource(ASSET_KEY.CLOSE_BUTTON),
})`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 8%;
  right: -3rem;
  cursor: pointer;

  @media only screen and (min-width: 1400px) {
    top: 15%;
  }

  @media only screen and (min-width: 1800px) {
    top: 20%;
  }

  @media only screen and (min-width: 2100px) {
    top: 28%;
  }

  @media only screen and (max-width: 1300px) and (min-height: 900px) {
    top: 18%;
  }
`;

