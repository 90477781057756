module.exports = {
  dimensions: {
    width: 230,
    height: 202
  },
  optical: {
    top: 26,
    left: 64,
    right: 62,
    bottom: 64
  }
};