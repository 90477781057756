import screens from '..';
import { connect } from '../../node_modules/react-redux';
import { setAuthCallbackPurchaseData as _setAuthCallbackPurchaseData } from '../../state/actions/callbackIntent';
import { isLTOPurchase as _isLTOPurchase, setPurchaseProfile as _setPurchaseProfile, setPurchaseType as _setPurchaseType } from '../../state/actions/prePurchase';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
import LimitedTimeOffer from './LimitedTimeOffer';
var mapStateToProps = function mapStateToProps(state) {
  return {
    actionCommands: state.actionCommands.actions.find(function (action) {
      var _action$payload;
      return (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    })
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setPurchaseType: function setPurchaseType(value) {
      dispatch(_setPurchaseType(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    setAuthCallbackPurchaseData: function setAuthCallbackPurchaseData(purchaseData) {
      dispatch(_setAuthCallbackPurchaseData(purchaseData));
    },
    setPurchaseProfile: function setPurchaseProfile(purchaseProfile) {
      dispatch(_setPurchaseProfile(purchaseProfile));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LimitedTimeOffer);