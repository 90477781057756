import { SET_LOBBY_NEWS, SET_LOBBY_BACKGROUND_PROMO } from './types';
export var setLobbyNews = function setLobbyNews(newsList) {
  return {
    type: SET_LOBBY_NEWS,
    payload: newsList
  };
};
export var setLobbyBackgroundPromo = function setLobbyBackgroundPromo(background) {
  return {
    type: SET_LOBBY_BACKGROUND_PROMO,
    payload: background
  };
};