import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'CONSUMABLES';
var initialState = {
  active: {},
  free: {},
  showVipAccessModal: true,
  isVipRoomUnlocked: false,
  unlimitedVipAccess: false,
  outOfCoins: {},
  ltoOffer: {},
  timedBonus: {}
};
var consumablesSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setActiveConsumables: function setActiveConsumables(state, action) {
      state.active = action.payload;
    },
    showVipAccessModal: function showVipAccessModal(state, action) {
      state.showVipAccessModal = action.payload;
    },
    setVipRoomUnlocked: function setVipRoomUnlocked(state, action) {
      state.isVipRoomUnlocked = action.payload;
    },
    setUnlimitedVipAccess: function setUnlimitedVipAccess(state, action) {
      state.unlimitedVipAccess = action.payload;
    },
    setOutOfCoinOfferResponse: function setOutOfCoinOfferResponse(state, action) {
      state.outOfCoins = action.payload;
    },
    setLTOOffer: function setLTOOffer(state, action) {
      state.ltoOffer = action.payload;
    },
    clearLTOOffer: function clearLTOOffer(state) {
      state.ltoOffer = {};
    },
    setTimedBonus: function setTimedBonus(state, action) {
      state.timedBonus = action.payload;
    }
  }
});
var actions = consumablesSlice.actions,
  reducer = consumablesSlice.reducer;
var setActiveConsumables = actions.setActiveConsumables,
  showVipAccessModal = actions.showVipAccessModal,
  setVipRoomUnlocked = actions.setVipRoomUnlocked,
  setUnlimitedVipAccess = actions.setUnlimitedVipAccess,
  setOutOfCoinOfferResponse = actions.setOutOfCoinOfferResponse,
  setLTOOffer = actions.setLTOOffer,
  clearLTOOffer = actions.clearLTOOffer,
  setTimedBonus = actions.setTimedBonus;
export { setActiveConsumables, showVipAccessModal, setVipRoomUnlocked, setUnlimitedVipAccess, setOutOfCoinOfferResponse, setLTOOffer, clearLTOOffer, setTimedBonus };
export default reducer;