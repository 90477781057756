import screens from '..';
import { connect } from '../../node_modules/react-redux';
import News from './News';
var mapStateToProps = function mapStateToProps(state) {
  return {
    gameInfo: state.gameInfo,
    coin: state.coin.value,
    assetsUrls: state.app.assetsUrls,
    actionCommands: state.actionCommands.actions.find(function (action) {
      var _action$payload;
      return (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    })
  };
};
export default connect(mapStateToProps, null)(News);