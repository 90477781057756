import React from 'react';
import styled from 'styled-components';

function GradientLabel({ label, gradentLabelTextStyle = {} }) {
  return (
    <GradientLabelWrapper theme={gradentLabelTextStyle} data-text={label}>
      <span>{label}</span>
    </GradientLabelWrapper>
  );
}

export default GradientLabel;

const GradientLabelWrapper = styled.p`
  position: relative;
  z-index: 10;
  display: inline;
  font-weight: ${({ theme }) => theme?.fontWeight || 700};
  text-align: center;
  font-size: ${({ theme }) => theme?.fontSize || 1.5}rem;
  margin-top: ${({ theme }) => theme?.marginTop || 0}rem;
  margin-left: ${({ theme }) => theme?.marginLeft || 0}rem;
  letter-spacing: ${({ theme }) => theme?.letterSpacing || 0}px;

  span {
    position: relative;
    background: ${({ theme }) => theme?.background};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.7);
  }
`;

